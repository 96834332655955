import { IInvalidParametersError, ILink, IPaymentResult, IPaymentResultDto } from './commonTypes';

export async function createSddMandate(
  link: ILink,
  resourceToken: string,
  createSddMandate: ICreateSddMandate,
): Promise<ICreateSddMandateResult | IInvalidParametersError> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const createSddMandateDto = mapToDto(createSddMandate);

  const requestOptions: RequestInit = {
    body: JSON.stringify(createSddMandateDto),
    headers: headers,
    method: link.methods[0],
  };

  const response = await fetch(link.href, requestOptions);

  if (response.status === 422) {
    const error = (await response.json()) as IInvalidParametersError;
    return error;
  } else if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const resultDto = (await response.json()) as ICreateSddMandateResultDto;
    const result = mapFromDto(resultDto);

    return result;
  }
}

const mapToDto = (payload: ICreateSddMandate): ICreateSddMandateDto => ({
  type: payload.type,
  ...{
    company_name: payload.companyName,
    district_court_code: payload.districtCourtCode,
    first_name: payload.firstName,
    last_name: payload.lastName,
    postal_address: payload.postalAddress && {
      line1: payload.postalAddress.line1,
      line2: payload.postalAddress.line2,
      number: payload.postalAddress.number,
      state: payload.postalAddress.state,
      town_city: payload.postalAddress.townCity,
      zip: payload.postalAddress.zip,
    },
    registration_number: payload.registrationNumber,
    registration_number_code: payload.registrationNumberCode,
  },
});

const mapFromDto = (dto: ICreateSddMandateResultDto): ICreateSddMandateResult => ({
  links: dto._links,
  status: dto.status,
  succeeded: dto.succeeded,
  workflow: dto.workflow,
});

// Types

export interface ICreateSddMandate {
  type?: string;
  firstName?: string;
  lastName?: string;
  postalAddress?: IRegisteredAddress;
  companyName?: string;
  registrationNumberCode?: string;
  registrationNumber?: string;
  districtCourtCode?: string;
}

interface IRegisteredAddress {
  line1: string;
  line2?: string;
  number: string;
  townCity: string;
  state?: string;
  zip: string;
}

interface ICreateSddMandateResult extends IPaymentResult {
  succeeded: boolean;
}

// DTO types (in snake_case)

export interface ICreateSddMandateDto {
  type?: string;
  first_name?: string;
  last_name?: string;
  postal_address?: IRegisteredAddressDto;
  company_name?: string;
  registration_number_code?: string;
  registration_number?: string;
  district_court_code?: string;
}

interface IRegisteredAddressDto {
  line1: string;
  line2?: string;
  number: string;
  town_city: string;
  state?: string;
  zip: string;
}

interface ICreateSddMandateResultDto extends IPaymentResultDto {
  succeeded: boolean;
}
