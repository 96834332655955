import {
  BankAccountType,
  IInvalidParametersError,
  ILink,
  IPaymentResult,
  IPaymentResultDto,
  PaymentType,
} from './commonTypes';

export async function authorizePayment(
  link: ILink,
  resourceToken: string,
  payload: IAuthorizeCardPayment | IAuthorizeSddPayment,
): Promise<IAuthorizePaymentResult | IInvalidParametersError> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestOptions: RequestInit = {
    body: JSON.stringify(mapToDto(payload)),
    headers: headers,
    method: link.methods[0],
  };

  const response = await fetch(link.href, requestOptions);

  if (response.status === 422) {
    const error = (await response.json()) as IInvalidParametersError;
    return error;
  } else if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const resultDto = (await response.json()) as IAuthorizePaymentResultDto;
    const result = mapFromDto(resultDto);

    return result;
  }
}

const mapToDto = (
  payload: IAuthorizeCardPayment | IAuthorizeSddPayment,
): IAuthorizeCardPaymentDto | IAuthorizeSddPaymentDto => {
  if (isIAuthorizeCardPayment(payload)) {
    return {
      type: payload.type,
      ...{ token: payload.token },
    };
  }

  return {
    bank_account_type: payload.bankAccountType,
    email: payload.email,
    iban: payload.iban,
    type: payload.type,
  };
};

const mapFromDto = (dto: IAuthorizePaymentResultDto): IAuthorizePaymentResult => ({
  links: dto._links,
  status: dto.status,
  succeeded: dto.succeeded,
  workflow: dto.workflow,
});

interface IAuthorizePaymentBase {
  type: PaymentType;
}

interface IAuthorizeCardPayment extends IAuthorizePaymentBase {
  token: string;
}

function isIAuthorizeCardPayment(
  value: IAuthorizeCardPayment | IAuthorizeSddPayment,
): value is IAuthorizeCardPayment {
  return (value as IAuthorizeCardPayment).type === PaymentType.Card;
}

type IAuthorizeCardPaymentDto = IAuthorizeCardPayment;

interface IAuthorizeSddPayment extends IAuthorizePaymentBase {
  iban: string;
  email: string;
  bankAccountType: BankAccountType;
}

interface IAuthorizeSddPaymentDto {
  iban: string;
  email: string;
  type: PaymentType;
  bank_account_type: BankAccountType;
}

interface IAuthorizePaymentResultDto extends IPaymentResultDto {
  succeeded: boolean;
}

interface IAuthorizePaymentResult extends IPaymentResult {
  succeeded: boolean;
}

export enum SupportedBackendErrors {
  PaymentMethod = 'payment_method',
  Iban = 'iban',
  Email = 'email',
  RegistrationNumber = 'registration_number',
}
